import { React, useState, useEffect } from 'react'
import { Toast } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons'
import './userInfo.css'
import _ from 'lodash'
import { getInfo, updateUserInfo } from '../request/api'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function UserInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false)
  const [index, setIndex] = useState(1)
  const [nameValue, setNameValue] = useState('')
  const [phoneValue, setPhoneValue] = useState('')
  const [addressValue, setAddressValue] = useState('')
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [windowTitle, setWindowTitle] = useState('')
  const [disableds, setDisableds] = useState(false)
  const openWindow = (i) => {
    switch (i) {
      case 1:
        setIndex(i)
        setWindowTitle(t('l9'))
        setShow(true)
        break;
      case 2:
        if (addressValue != '') {
          Toast.show({
            content: t('t11'),
            duration: 800
          })
        } else {
          setIndex(i)
          setShow(true)
          setWindowTitle(t('ty4'))
        }
        break;
      case 3:
        setIndex(i)
        setWindowTitle(t('l10'))
        setShow(true)
        break;
    }
  }
  const getData = async () => {
    Toast.show({
      icon: 'loading',
      content: t('t4'),
      duration: 800
    })
    const res = await getInfo({})
    if (res.code == 0) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('warning');
      localStorage.removeItem('tan');
      localStorage.removeItem('tabber');
      Toast.show({
        icon: 'fail',
        content: res.info,
      })
      setTimeout(() => {
        window.location.hash = "/login"
      }, 2500);
    }
    setNameValue(res.data.nickname)
    setPhoneValue(res.data.mobile.replace(/\s*/g, ""))
    setAddressValue(res.data.uget_address_trc)
    if (res.data.uget_address_trc == '') {
      setDisableds(false)
    } else {
      setDisableds(true)
    }
  }
  const amend = async () => {
    if (password2 != "" && password1 == "") {
      Toast.show({
        content: "Please fill in the old password",
      })
      return false
    }
    if (password1 != "" && password2 == "") {
      Toast.show({
        content: "Please fill in the new password",
      })
      return false
    }
    if (password1.length > 6 || password2.length > 6) {
      Toast.show({
        icon: 'fail',
        content: t('t22'),
      })
      return false
    }
    await updateUserInfo({
      nickname: nameValue,
      mobile: phoneValue,
      password: password2,
      oldpassword: password1,
      uget_address_trc: addressValue,
    }).then(res => {
      if (res.code == 1) {
        Toast.show({
          icon: 'success',
          content: res.info,
          duration: 1200
        })
        setTimeout(() => {
          navigate('/User')
        }, 1200);
      } else {
        Toast.show({
          icon: 'fail',
          content: res.info,
        })
      }
    })
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className='userInfo'>
      <div className='header'>
        <div className='backIcon' onClick={() => { navigate('/User') }}>
          <LeftOutline />
        </div>
        <p>{t('yh1')}</p>
      </div>
      <div className='userInfo_box'>
        <div className='info_from'>
          <div className='info_from_title'>
            <p>{t('l9')}</p>
            <p onClick={() => { openWindow(1) }}>{t('yh2')}</p>
          </div>
          <input type="text" disabled value={nameValue} />
        </div>
        <div className='info_from'>
          <div className='info_from_title'>
            <p>{t('yh4')}</p>
            <p></p>
          </div>
          <input type="text" disabled value={phoneValue} />
        </div>
        <div className='info_from'>
          <div className='info_from_title'>
            <p>{t('ty4')}</p>
            <p onClick={() => { openWindow(2) }}>{t('yh2')}</p>
          </div>
          <input type="text" disabled value={addressValue} />
        </div>
        <div className='info_from'>
          <div className='info_from_title'>
            <p>{t('l10')}</p>
            <p onClick={() => { openWindow(3) }}>{t('yh2')}</p>
          </div>
        </div>
      </div>
      {
        show && <div className='modelWindow'>
          <div className='window_center'>
            <div className='userInfo_window'>
              <p>{windowTitle}</p>
              {
                index == 1 && <input type="text" value={nameValue || ''} onChange={(e) => { setNameValue(e.target.value) }} />
              }
              {
                index == 2 && <input disabled={disableds} type="text" value={addressValue || ''} onChange={(e) => { setAddressValue(e.target.value) }} />
              }
              {
                index == 3 && <div style={{ margin: '0 0 10px 0' }}>
                  <div className='newPassword'>
                    <label htmlFor="password1">{t('yh6')}:</label>
                    <input type="text" id="password1" value={password1 || ''} onChange={(e) => { setPassword1(e.target.value) }} />
                  </div>
                  <div className='newPassword'>
                    <label htmlFor="password2">{t('yh7')}:</label>
                    <input type="text" id="password2" value={password2 || ''} onChange={(e) => { setPassword2(e.target.value) }} />
                  </div>
                </div>
              }
              <div className='pop_button'>
                <p onClick={amend}>{t('yh3')}</p>
                <p onClick={() => { setShow(false) }}>{t('ty2')}</p>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
